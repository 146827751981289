<template>
  <div>
    <albums-list lang="RU"></albums-list>
  </div>
</template>

<script>
import AlbumsList from './AlbumsList.vue'

/*eslint-disable */
export default {
  components: {
    AlbumsList,
  },
  data() {
    return {}
  },
}
</script>
