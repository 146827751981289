var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{attrs:{"id":"card-content-types"}},[_c('h1',{staticClass:"mt-3"},[_vm._v(" Альбомы "+_vm._s(_vm.lang)+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-actions',{ref:"cardAction",attrs:{"title":"Только названия","actionCollapse":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.showOnlyTitles),callback:function ($$v) {_vm.showOnlyTitles=$$v},expression:"showOnlyTitles"}},[_vm._v(" Только названия ")])],1)])],1)],1)],1),_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('vue-good-table',{staticClass:"albums-list",attrs:{"columns":_vm.columns,"rows":_vm.albumsList,"search-options":{
              enabled: false,
              },"select-options":{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: false, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: false,
            },"line-numbers":!_vm.showOnlyTitles},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'title')?_c('span',{staticClass:"text-nowrap"},[(!_vm.showOnlyTitles)?_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.cover}}):_vm._e(),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.title))])],1):(props.column.field === 'votes')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar-group',{attrs:{"size":"34px"}},[_vm._l((props.row.votes),function(vote){return _c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(vote.user.name + ' - ' + vote.rating.toString()),expression:"vote.user.name + ' - ' + vote.rating.toString()",modifiers:{"hover":true,"bottom":true}}],key:vote.id,staticClass:"pull-up",attrs:{"src":vote.user.image,"badge":vote.rating.toString(),"badge-variant":(vote.rating <= 4) ? 'danger' : (vote.rating <= 7) ? 'warning' : 'success'}})}),_c('h6',{staticClass:"align-self-center cursor-pointer ml-1 mb-0"},[_vm._v(" ("+_vm._s(props.row.votes.length)+") ")])],2)],1):(props.column.field === 'release_date')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.release_date))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }